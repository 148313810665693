<template>
  <v-flex>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        v-for="(item, index) in inputCard"
        :key="index"
      >
        <v-card class="mx-auto" max-width="400">
          <v-card-title primary-title>
            <v-card-title>{{ item.title }}</v-card-title>
            <v-icon>{{ item.icon }}</v-icon>
          </v-card-title>
          <v-card-actions>
            <v-col>
              <v-file-input
                dense
                show-size
                :label="item.label"
                :ref="item.ref"
                @change="onFileUpload($event, index)"
                :messages="item.isLoading ? messageLoading : ''"
                :loading="item.isLoading"
                :disabled="item.isLoading"
              ></v-file-input>
            </v-col>
            <v-btn
              :disabled="item.disabled"
              icon
              @click="sendFile(index, item.ref)"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar color="success" v-model="showSnackBar" bottom
      >CSV enviado com sucesso!</v-snackbar
    >
    <v-snackbar color="error" v-model="showSnackBarErrors" bottom
      >Ouve um erro durante a operação!!!</v-snackbar
    >
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import api from '@/plugins/api';

export default {
  name: 'BuzzReports',
  data: () => ({
    messageLoading: 'enviando...',
    showSnackBar: false,
    showSnackBarErrors: false,
    file: null,
    inputCard: [
      {
        title: 'CSV - Enriquecidos',
        icon: 'upload_file',
        label: 'Upload CSV Enriquecido',
        ref: 'enriched',
        disabled: true,
        isLoading: false
      }
    ]
  }),

  methods: {
    ...mapActions(['setTitle']),

    onFileUpload(event, index) {
      this.file = event;
      this.inputCard[index].disabled = false;
    },

    async sendFile(index, ref) {
      this.inputCard[index].isLoading = true;
      this.inputCard[index].disabled = true;

      if (ref === 'enriched') {
        await this.inputEnriched();
      } else {
        await this.inputPending();
      }

      await this.$nextTick(() => {
        this.file = null;
        this.$refs[ref][0].reset();
        this.inputCard[index].isLoading = false;
        this.showSnackBar = true;
      });
    },

    async inputPending() {
      const formData = new FormData();
      formData.append('csv', this.file);
      formData.append('name', this.file.name);
      formData.append('file', this.file);

      return api.buzzGetCsvPending(formData)
        .then((res) => {
          const date = new Date(Date.now()).toLocaleDateString('pt-br');
          const filename = `${date}_pending_${this.file.name}`;
          const blob = new Blob([res.data], {
            type: 'text/csv;charset=utf-8'
          });

          saveAs(blob, filename);
          this.showSnackBar = true;
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });
    },

    async inputEnriched() {
      const formData = new FormData();
      formData.append('csv', this.file);
      formData.append('name', this.file.name);
      formData.append('file', this.file);

      return api.buzzInputCsvEnriched(formData)
        .then((res) => {
          this.saveAsCsv('enriched', 'invalid', res);
          this.saveAsCsv('enriched', 'duplicated', res);
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });
    },

    saveAsCsv(ref, type, res) {
      if (res.data[type] !== null) {
        const date = new Date(Date.now()).toLocaleDateString('pt-br');
        const filename = `${date}_${ref}_${type}_${this.file.name}`;
        const blob = new Blob([res.data[type]], {
          type: 'text/csv;charset=utf-8'
        });

        saveAs(blob, filename);
        this.showSnackBar = true;
      }
    }
  },

  created() {
    this.setTitle({ title: 'CSV - Buzz Financeiro' });
  },
};
</script>
